body {
  color: #fff;
  font-family: Lexend, sans-serif;
  font-size: 18px;
  line-height: 23px;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  letter-spacing: -1px;
  font-size: 100px;
  font-weight: 700;
  line-height: 100px;
}

h2 {
  letter-spacing: -.4px;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
}

h3 {
  font-size: 36px;
  font-weight: 400;
}

h4 {
  letter-spacing: -.24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

ul {
  padding-left: 0;
}

ul li {
  list-style: none;
}

.wrapper {
  width: 100%;
  background: #1d1f24;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

._container {
  max-width: 1224px;
  width: 100%;
  padding: 0 24px;
}

._bold {
  white-space: nowrap;
  font-weight: 700;
}

.select {
  color: #fff;
  cursor: pointer;
  background-color: #18191e;
  border: 1px solid #2f2f32;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 17px;
  font-weight: 500;
  display: flex;
}

.select .globe {
  margin-right: 8px;
}

.select .chevron-down {
  margin-left: 8px;
}

.dot {
  color: #fcd3af;
  padding: 0;
}

/*# sourceMappingURL=index.8cf4e787.css.map */
