$base-font-size: 14px;

$inner-width: 1235px;

$text-color: white;
$text-secondary: #bbbcbd;
$text-color-dark: #131315;
$primary: #fcd3af;

$bg_lighter: #2f2f32;
$bg_darker: #18191e;
$bg: #1d1f24;

$error_color: #dc7547;

$extra-light-font-weight: 200;
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$semi-bold-font-weight: 600;
$bold-font-weight: 700;
