@import 'common/variables.scss';
@import 'common/mixins.scss';

body {
  font-family: 'Lexend', sans-serif;
  font-size: 18px;
  line-height: 23px;
  color: white;
}

*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 100px;
  font-weight: $bold-font-weight;
  line-height: 100px;
  letter-spacing: -1px;
}

h2 {
  font-size: 40px;
  font-weight: $bold-font-weight;
  line-height: 56px;
  letter-spacing: -0.4px;
}

h3 {
  font-size: 36px;
  font-weight: 400;
}

h4 {
  font-size: 24px;
  font-weight: $bold-font-weight;
  line-height: 30px;
  letter-spacing: -0.24px;
}

ul {
  padding-left: 0;

  li {
    list-style: none;
  }
}

.wrapper {
  @include flex-row(center, null);
  width: 100%;
  background: $bg;
}

._container {
  padding: 0 24px;
  max-width: 1224px;
  width: 100%;
}

._bold {
  font-weight: 700;
  white-space: nowrap;
}

.select {
  padding: 16px 16px 17px;
  border: 1px solid $bg_lighter;
  background-color: $bg_darker;
  color: $text-color;
  font-weight: $medium-font-weight;
  cursor: pointer;
  @include flex-row(null, center);

  .globe {
    margin-right: 8px;
  }

  .chevron-down {
    margin-left: 8px;
  }
}

.dot {
  color: $primary;
  padding: 0;
}
