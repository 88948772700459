@use 'sass:math';
@import 'variables.scss';

@function toRem($val) {
  $remValue: math.div($val, $base-font-size) + rem;
  @return $remValue;
}

$font-sizes: (
  small-spaced: (
    15px,
    30px,
  ),
  regular: (
    17px,
    20px,
  ),
  regular-spaced: (
    17px,
    30px,
  ),
  small-header: (
    23px,
    28px,
  ),
  header: (
    32px,
    48px,
  ),
);

@mixin generate-font-size($size) {
  $font-vals: map-get($font-sizes, $size);
  font-size: toRem(nth($font-vals, 1));
  @if (length($font-vals) > 1) {
    line-height: toRem(nth($font-vals, 2));
  }
}

@mixin flex($dir: row, $justifyContent: null, $alignItems: null) {
  display: flex;
  flex-direction: $dir;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin flex-column($justifyContent: null, $alignItems: null) {
  @include flex(column, $justifyContent, $alignItems);
}

@mixin flex-row($justifyContent: null, $alignItems: null) {
  @include flex(row, $justifyContent, $alignItems);
}

@mixin flex-center {
  @include flex(row, center, center);
}

@mixin flex-row-v-center {
  @include flex(row, $alignItems: center);
}

@mixin flex-column-h-center {
  @include flex(column, $alignItems: center);
}

@mixin push-auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin push-ends($top: null, $bottom: null) {
  margin-top: $top;
  margin-bottom: $bottom;
}

@mixin push-sides($left: null, $right: null) {
  margin-left: $left;
  margin-right: $right;
}
